import Link from "next/link";
import { IconButton } from "./IconButton";
import { useMobileDetect } from "../../../hooks";
import NavBarLink from "./NavBarLink";
import styles from "./styles/navbarleft.module.css";

function Mobile({ isMobile, handleShowMobileDrawer }) {
  if (!isMobile) return null;
  return (
    <>
      <IconButton size="3rem" title="Menu" handleClick={handleShowMobileDrawer}>
        <svg xmlns="http://www.w3.org/2000/svg" height="8px" width="16px" viewBox="0 0 16 8">
          <path fill="#191919" fillRule="nonzero" d="M0 0h16v1H0V0zm0 7h16v1H0V7z"></path>
        </svg>
      </IconButton>
      <Link href="/search" prefetch={false} legacyBehavior>
        <a>
          <IconButton size="3rem" title="Search">
            <svg xmlns="http://www.w3.org/2000/svg" height="18px" width="18px" viewBox="0 0 18 18">
              <path
                fill="#191919"
                fillRule="nonzero"
                d="M11.05 11.917a6.739 6.739 0 1 1 .87-.872l.016.025L18 17.134l-.866.866-6.064-6.064-.02-.019zm-.431-1.298a5.488 5.488 0 1 0-7.762-7.762 5.488 5.488 0 0 0 7.762 7.762z"
              ></path>
            </svg>
          </IconButton>
        </a>
      </Link>
    </>
  );
}

function Default({ navigation, isMobile, handleShowDrawer, showDrawer, navList }) {
  if (isMobile) return null;

  return (
    <>
      {navigation.map((item) => (
        <NavBarLink
          navLink={item}
          key={item.key}
          handleShowDrawer={handleShowDrawer}
          underline={navList?.key === item.key && showDrawer}
        />
      ))}
    </>
  );
}

export function NavBarLeft({
  navigation,
  handleShowMobileDrawer,
  handleShowDrawer,
  toggleDrawer,
  showDrawer,
  navList,
}) {
  const currentDevice = useMobileDetect();

  if (!navigation) return null;
  const isMobile = currentDevice.isMobile();

  return (
    <div className={styles.wrapper} onMouseLeave={() => toggleDrawer(false)}>
      <Mobile isMobile={isMobile} handleShowMobileDrawer={handleShowMobileDrawer} />
      <Default
        navigation={navigation}
        isMobile={isMobile}
        handleShowDrawer={handleShowDrawer}
        showDrawer={showDrawer}
        navList={navList}
      />
    </div>
  );
}
