"use client";

import HubspotForm from "react-hubspot-form";
import { useRouter } from "next/navigation";
import { Button } from "@components/Button";
import { Drawer } from "@components/Drawer";
import { CartDrawerHeader } from "@components/Cart/Drawer/CartDrawerHeader";
import {
  useKotnSupplyQuoteFormDispatch,
  useKotnSupplyQuoteFormState,
} from "@hooks/usekotnSupplyQuoteForm";
import styles from "./styles/kotnSupplyDrawer.module.css";
export function KotnSupplyQuoteDrawer() {
  const { isOpen } = useKotnSupplyQuoteFormState();
  const { closeQuoteForm } = useKotnSupplyQuoteFormDispatch();

  const iframe = `<iframe src="https://forms.monday.com/forms/embed/abcefe2bd66d323e84ccaab86e9c6c5c?r=use1" width="100%" height="100%" style="border: 0; box-shadow: 5px 5px 56px 0px rgba(0,0,0,0.25);"></iframe>`;

  return (
    <Drawer isOpen={isOpen} close={() => {}}>
      <CartDrawerHeader handleCloseDrawer={() => closeQuoteForm()} />
      <div className={styles.wrapper}>
        <div className={styles.form} dangerouslySetInnerHTML={{ __html: iframe }} />
      </div>
    </Drawer>
  );
}

export function KotnSupplyQuoteButton() {
  const router = useRouter();
  const { openQuoteForm } = useKotnSupplyQuoteFormDispatch();

  if (/kotn-supply/.test(router.asPath)) {
    return (
      <div>
        <Button
          additionalStyles={styles.navButton}
          handleClick={() => {
            openQuoteForm();
          }}
        >
          {"Get Quote"}
        </Button>
      </div>
    );
  }

  return null;
}
