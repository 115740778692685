"use client";

import { useState, useEffect } from "react";
import { useRouter } from "next/navigation";
import Link from "next/link";
import Cookies from "js-cookie";
import { IconButton } from "./IconButton";
import Cta from "../../Cta";
import { CURRENCIES } from "@lib/constants";
import { DropDown } from "@components/DropDown";
import { LanguageToggle } from "../AnnouncementBar/LanguageToggle";
import { useMobileDetect, useCurrencyDispatch, useCurrencyState } from "@hooks/index";
import styles from "./styles/mobile.module.css";

function CurrencyDropdown({ toggleShowMobileDrawer }) {
  const dispatch = useCurrencyDispatch();
  const { currency } = useCurrencyState();

  const handleSelectedItemChange = ({ selectedItem }) => {
    dispatch({ type: "set", currency: selectedItem });
    Cookies.set("cart_currency", selectedItem);
    toggleShowMobileDrawer();
  };

  return (
    <div>
      {currency && (
        <DropDown
          items={CURRENCIES}
          selectedItem={currency}
          handleSelectedItemChange={handleSelectedItemChange}
        />
      )}
    </div>
  );
}

function MobileDrawerSubMenu({ items, handleShowSubMenu, toggleShowMobileDrawer }) {
  const onClick = () => handleShowSubMenu();
  if (items.length === 0) return null;
  const hideMobileDrawer = () => toggleShowMobileDrawer();

  return (
    <>
      <div className={styles.label} onClick={onClick}>
        &larr; Back
      </div>
      <div>
        {items.menus &&
          items.menus.map((menu) => (
            <section key={menu.key}>
              <h3 className={styles.heading}>{menu.title}</h3>
              <ul>
                {menu.links &&
                  menu.links.map((link) => (
                    <li className={styles.link} key={link.key} onClick={hideMobileDrawer}>
                      <Cta navLink={link} />
                    </li>
                  ))}
              </ul>
            </section>
          ))}
      </div>
    </>
  );
}

function Arrow() {
  return (
    <IconButton size="1.5rem">
      <svg width="0.75rem" height="0.75rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 11">
        <path
          fill="#191919"
          d="M10.44 6.408H.504V5.192h9.936c-.48-.432-1.584-1.6-2.608-2.624L5.928.6H7.72c1.152 1.2 3.568 3.872 4.944 5.2-1.36 1.36-3.776 4-4.944 5.2H5.928c.688-.688 1.312-1.344 1.904-1.952 1.024-1.024 2.144-2.176 2.608-2.64z"
        />
      </svg>
    </IconButton>
  );
}

function MobileDrawerMenu({ navigation, showSubNav, handleShowSubMenu, toggleShowMobileDrawer }) {
  if (showSubNav) return null;
  return (
    <>
      {navigation &&
        navigation.map((subnav) => {
          if (!subnav.menus) {
            return (
              <div
                className={styles.title}
                key={subnav.title}
                onClick={() => toggleShowMobileDrawer()}
              >
                <Cta navLink={subnav} />
                <Arrow />
              </div>
            );
          }
          return (
            <div key={subnav.title} onClick={() => handleShowSubMenu(subnav)}>
              <div className={styles.title}>
                <span>{subnav.title}</span>
                <Arrow />
              </div>
            </div>
          );
        })}
    </>
  );
}

export function MobileDrawer({ navigation, showMobileDrawer, toggleShowMobileDrawer }) {
  const { locale } = useRouter();
  const [showSubNav, setShowSubNav] = useState(false);
  const [subNavList, setSubNavList] = useState([]);
  const [isQuebec, setIsQuebec] = useState(false);

  const locationCookie = Cookies.get("location");

  useEffect(() => {
    const location = locationCookie && JSON.parse(locationCookie);
    const isQuebec = location?.country === "CA" && location?.region === "QC";
    if (location) setIsQuebec(isQuebec);
  }, [locationCookie]);

  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();
  const isFrench = locale === "fr";

  if (!isMobile) {
    return null;
  }

  const handleShowSubMenu = (subNavList) => {
    if (subNavList && isMobile) {
      setShowSubNav(true);
      setSubNavList(subNavList);
      return;
    }
    setShowSubNav(false);
    setSubNavList([]);
  };

  if (!showMobileDrawer) {
    document.body.style.overflow = "unset";
    return null;
  } else {
    document.body.style.overflow = "hidden";
  }

  return (
    <div className={styles.wrapper}>
      {!showSubNav && <div className={styles.label}>Menu</div>}
      <MobileDrawerMenu
        navigation={navigation}
        showSubNav={showSubNav}
        handleShowSubMenu={handleShowSubMenu}
        toggleShowMobileDrawer={toggleShowMobileDrawer}
      />
      <MobileDrawerSubMenu
        items={subNavList}
        handleShowSubMenu={handleShowSubMenu}
        toggleShowMobileDrawer={toggleShowMobileDrawer}
      />

      {!showSubNav && (
        <>
          <hr className={styles.divider} />
          {!isFrench && (
            <div className={styles.title}>
              <Link href="/account/orders" passHref prefetch={false} legacyBehavior>
                <a>Account</a>
              </Link>
            </div>
          )}
          <div className={styles.title}>
            <Link href="/help" passHref prefetch={false} legacyBehavior>
              <a>Support</a>
            </Link>
          </div>
          <div className={styles.title}>
            <Link href="/kotn-supply" passHref prefetch={false} legacyBehavior>
              <a>Custom Apparel</a>
            </Link>
          </div>
          <hr className={styles.divider} />
          <CurrencyDropdown toggleShowMobileDrawer={toggleShowMobileDrawer} />
          <LanguageToggle />
        </>
      )}
    </div>
  );
}
