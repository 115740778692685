"use client";

import { useState, useEffect } from "react";
import { NextSeo } from "next-seo";
import { useRouter } from "next/navigation";
import { getOpenGraph } from "@lib/getOpenGraph";
import dynamic from "next/dynamic";

const STORE_HUBS = ["/mtr-emails", "/la-emails", "/van-emails", "/to-emails"];

// Dynamically imported components
const CartDrawer = dynamic(() => import("@components/Cart").then((mod) => mod.CartDrawer), {
  ssr: false,
});
const KotnSupplyQuoteDrawer = dynamic(
  () =>
    import("@components/KotnSupply/KotnSupplyQuoteDrawer").then((mod) => mod.KotnSupplyQuoteDrawer),
  { ssr: false }
);
const Newsletter = dynamic(() => import("./Newsletter").then((mod) => mod.Newsletter), {
  ssr: false,
});
const Footer = dynamic(() => import("./Footer").then((mod) => mod.default), { ssr: false });

function isStoreHub(path) {
  return STORE_HUBS.includes(path);
}

export function Default({ seo, title, url, children, modal, subscribed, footer }) {
  const { seoTitle, seoDescription, openGraphImage, twitterCard } = seo;
  const openGraphImages = getOpenGraph(openGraphImage, title);
  const router = useRouter();
  const hideModal = isStoreHub(router.asPath);

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  let canonical = null;
  if (url) {
    canonical = url + router.asPath;
  }

  return (
    <>
      {process.env.NEXT_PUBLIC_STAGING === "true" ? (
        <NextSeo noindex />
      ) : (
        <NextSeo
          title={seoTitle}
          titleTemplate="%s"
          description={seoDescription}
          canonical={canonical}
          openGraph={{
            type: "website",
            url: canonical,
            title: `${seoTitle}`,
            description: `${seoDescription}`,
            images: openGraphImages,
            site_name: "Kotn",
          }}
          twitter={{
            handle: "@kotnbasics",
            site: "@kotnbasics",
            cardType: `${twitterCard}`,
          }}
        />
      )}

      {children}

      {/* Dynamically loaded components */}
      {isClient && (
        <>
          <CartDrawer />
          <KotnSupplyQuoteDrawer />
          <Newsletter modal={modal} subscribed={subscribed || hideModal} />
          <Footer navItems={footer} />
        </>
      )}
    </>
  );
}
