import { useEffect, useRef } from "react";
import { useTranslation } from "../../../app/i18n/client";
import Link from "next/link";
import { useMobileDetect } from "@hooks/index";
import styles from "./announcementBar.module.css";

const CustomApparel = () => {
  const { t } = useTranslation("navbar");
  return (
    <div className={styles.announcementLink}>
      <Link href="https://kotn.com/kotn-supply" passHref prefetch={false} legacyBehavior>
        <a target="_blank">{t("custom-apparel")}</a>
      </Link>
    </div>
  );
};

const Stores = () => {
  const { t } = useTranslation("navbar");

  return (
    <div className={styles.announcementLink}>
      <Link href="https://kotn.com/stores" passHref prefetch={false} legacyBehavior>
        <a>{t("stores")}</a>
      </Link>
    </div>
  );
};

function Extole() {
  const { t } = useTranslation("navbar");
  const extoleRef = useRef();

  useEffect(() => {
    window?.extole?.createZone({ name: "global_header", element: extoleRef.current });
  }, []);

  return (
    <div className={styles.announcementLink}>
      <span id="extole_zone_global_header" ref={extoleRef}>
        {t("refer")}
      </span>
    </div>
  );
}

export function AnnouncementBarRight() {
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();

  return (
    <div className={styles.announcementRight}>
      {!isMobile && <Stores />}
      {!isMobile && <CustomApparel />}
    </div>
  );
}
