"use client";

import { usePathname, useRouter } from "next/navigation";
import { languages, cookieName } from "../../../app/i18n/settings";
import styles from "./styles/toggle.module.css";
import Cookies from "js-cookie";

export function LanguageToggle() {
  const router = useRouter();
  const pathname = usePathname();
  const currentLocale = pathname.split("/")[1];

  const handleChange = (newLocale) => {
    if (newLocale !== currentLocale) {
      const segments = pathname.split("/");
      if (languages.includes(segments[1])) {
        segments[1] = newLocale;
      } else {
        segments.unshift(newLocale);
      }
      const newPath = segments.join("/");

      Cookies.set(cookieName, newLocale, { path: "/" });
      router.push(newPath);
    }
  };

  return (
    <fieldset className={styles.container}>
      <legend>Language Selector</legend>
      {languages.map((lng) => (
        <div key={lng}>
          <input
            type="radio"
            id={`radio-${lng}`}
            name="language"
            value={lng}
            checked={currentLocale === lng}
            onChange={() => handleChange(lng)}
          />
          <label htmlFor={`radio-${lng}`}>{lng.toUpperCase()}</label>
        </div>
      ))}
    </fieldset>
  );
}

LanguageToggle.propTypes = {};
