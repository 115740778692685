import Link from "next/link";
import Image from "next/image";
import { urlFor } from "@lib/sanity";
import styles from "./styles/navbarcenter.module.css";

export function NavBarCenter({ logo }) {
  return (
    <div className={styles.wrapper}>
      <Link href="/" prefetch={false} legacyBehavior>
        <a>
          <Image alt="Kotn" src={urlFor(logo?.asset).url()} layout="fixed" width={80} height={16} />
        </a>
      </Link>
    </div>
  );
}
