"use client";

import { NavBar } from "./Navigation";
import { Alert } from "./Alert";
import { AnnouncementBar } from "./AnnouncementBar";
import styles from "./styles/header.module.css";
import { useRouter } from "next/navigation";

// Define the withRouter HOC
const withRouter = (WrappedComponent) => {
  return (props) => {
    const router = useRouter();
    return <WrappedComponent {...props} router={router} />;
  };
};

// Header component
function Header({ navigation, announcement, logo, brandStory, isPreview, router }) {
  return (
    <header className={styles.header}>
      {isPreview && <Alert />}
      <AnnouncementBar announcement={announcement} />
      <NavBar navigation={navigation} logo={logo} brandStory={brandStory} />
    </header>
  );
}

// Wrap the Header component with withRouter HOC
export default withRouter(Header);
